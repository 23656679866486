html {
			background-color: #AA193F;
			color: #FFFEF2;;
		}

		body {
			padding: 80px 0 0 0;
			margin: 0 auto;
		}

		img {
			width: 600px;
			display: block;
			margin: 0 auto;
		}

		h1 {
			font-family: PTSans, sans-serif;
			text-align: center;
			margin: 0;
		}

		h4 {
			font-family: PTSans, sans-serif;
			text-align: center;
			margin: 0;
		}

		h2 {
			font-family: PTSans, sans-serif;
			text-align: center;
		}
